import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useQuery } from 'react-query';
import Loader from '../../../Shared/Loader';

const CurrentStock = () => {
    const [sales, setSales] = useState([]);
    const [prod, setProd] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [sort, setSort] = useState('All');
    const [categories, setCategories] = useState([]);

    const sortRef = useRef('');
    const handleSort = () => {
        const sortValue = sortRef.current.value;
        setSort(sortValue);
    }


    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/mnt-rocket/getcategories`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setCategories(data);
            })
    }, []);

    useEffect(() => {
        fetch(`https://servers.clearsoftwares.net/mnt-rocket/getcurrentstock`)
            .then(res => res.json())
            .then(data => {
                const filtered = data.stocks.filter((product) => {
                    return product.stock > 0;
                });
                console.log(filtered);
                setProd(filtered);
                // console.log(data.stocks);
                // console.log(filtered);
                // setLoading(false)
            })
    }, [])

    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/mnt-rocket/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/mnt-rocket/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
            })
    }, []);

    const { data: products, isLoading } = useQuery(['products'], () => fetch('https://servers.clearsoftwares.net/mnt-rocket/getproducts').then(res => res.json()));



    // Stock Report

    // Sales Quantity

    let array = [];

    for (let i = 0; i < products?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === products[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === products[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array4.push(totalLoanReturn)
    }


    // Joining Balance To data

    let data = [];
    for (let i = 0; i < products?.length; i++) {
        const product = ({
            _id: products[i]._id, name: products[i]?.name,
            code: products[i].code, category: products[i].category,
            brand: products[i].brand, minimum: products[i].minimum,
            origin: products[i].origin, unit: products[i].unit, status: products[i].status,
            purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], opening: products[i].opening,
            stock: (products[i]?.opening) + array4[i] - array2[i], stockCost: (products[i].opening
                + array4[i] - array2[i]) * products[i].purchase,
        });

        data.push(product);
    }

    // const filtered = data?.filter((product) => product.stock>=1);

    // console.log(filtered);

    const sorted = sort === "All" ? data.sort(function (a, b) {
        return (a?.totalSale < b?.totalSale) ? 1 : ((a?.totalSale > b?.totalSale) ? -1 : 0);
    }) : data.filter(product => product.category === sort).sort(function (a, b) {
        return (a?.totalSale < b?.totalSale) ? 1 : ((a?.totalSale > b?.totalSale) ? -1 : 0);
    })

    if (isLoading) {
        return <Loader></Loader>
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = data.filter(product => product.name.includes(searchText) || product.name.toLowerCase().includes(searchText)
            || product.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const totalBalance = isSearching ? searchResult.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0) : sorted.reduce((total, currentValue) => total + parseFloat(currentValue.stockCost), 0);

    return (
        <div className='mx-2'>
            <h1 className='text-center text-lg mt-8'>Current Product Stocks</h1>
            <div className='flex justify-between mt-16'>
                <div className={`form-control w-40 max-w-xs mt-3 lg:mt-0 lg:mr-2`}>
                    <label class="label">
                        <span class="label-text">Select Category</span>
                    </label>
                    <select ref={sortRef} style={{ padding: '' }} onChange={handleSort} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                        <option selected>All</option>
                        {categories?.map(category => <option>{category?.name}</option>)}
                    </select>
                </div>
                <div>
                    <div className='text-right'>
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                            table="table-to-xls"
                            filename={`Stock Sheet`}
                            sheet={`stock-sheet`}
                            buttonText="Export to exel" />
                        {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
                    </div>
                    <div className='flex justify-end items-center my-4'>
                        <div class="form-control w-52">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="overflow-x-auto mt-4">
                <table id="table-to-xls" class="table w-1/2 mx-auto mb-20 text-xs">
                    <thead>
                        <tr className='text-center'>
                            <th className='bg-red-600 text-white normal-case'>SL</th>
                            <th className='bg-red-600 text-white normal-case'>Code</th>
                            <th className='bg-red-600 text-white normal-case'>Product Name</th>
                            <th className='bg-red-600 text-white normal-case'>Cost Price</th>
                            <th className='bg-red-600 text-white normal-case'>Sales Price</th>
                            <th className='bg-red-600 text-white normal-case'>Opening Balance</th>
                            <th className='bg-red-600 text-white normal-case'>Total Sales</th>
                            <th className='bg-red-600 text-white normal-case'>Total Purchase</th>
                            <th className='bg-red-600 text-white normal-case'>Current Stock</th>
                            <th className='bg-red-600 text-white normal-case'>Cost Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isSearching ? searchResult.map((product, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.purchase}</td>
                                <td>{product?.sell}</td>
                                <td className='text-blue-800'>{product?.opening}</td>
                                <td className='text-green-600'>{(product?.totalSale).toFixed(2)}</td>
                                <td className='text-yellow-700'>{(product?.totalPurchase).toFixed(2)}</td>
                                <td className='text-red-600 font-bold'>{(product?.stock).toFixed(2)}</td>
                                <td>{(product.stockCost).toFixed(2)}</td>
                            </tr>) : sorted.map((product, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.purchase}</td>
                                <td>{product?.sell}</td>
                                <td className='text-blue-800'>{product?.opening}</td>
                                <td className='text-green-600'>{product?.totalSale}</td>
                                <td className='text-yellow-700'>{product?.totalPurchase}</td>
                                <td className='text-red-600 font-bold'>{product?.stock}</td>
                                <td>{(product.stockCost).toFixed(2)}</td>
                            </tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'>Total</th>
                            <th className='bg-red-600 text-white normal-case'>{(totalBalance)?.toFixed(2)}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default CurrentStock;


{/* <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Stock Sheet`}
                    sheet={`stock-sheet`}
                    buttonText="Export to exel" />
                {/* <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button> */}
            // </div>
            // <div className='flex justify-center lg:justify-end items-center my-4'>
            //     <div class="form-control w-60">
            //         <input onChange={handleSearchResult} type="text" placeholder="Search Product" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
            //     </div>
            // </div> */}