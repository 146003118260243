import { sl } from 'date-fns/locale';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addBookingCustomer } from '../../../features/SalesSlice/SalesSlice';
import auth from '../../../Firebase/Firebase';

const BookingTable = ({ booking, setUpdateBooking }) => {
    const user = useAuthState(auth);
    const dispatch = useDispatch();

    // const { name, slots, price } = booking;
    // console.log(booking);
    const handleAddBookingCustomer = (booking) => {
        setUpdateBooking(booking);
        dispatch(addBookingCustomer(booking));


    }

    const handleDeleteBooking = (booking) => {
        const newdate = new Date();
        const date = newdate.toLocaleDateString();
        const time = newdate.toLocaleTimeString();
        const proceed = window.confirm('Ary You Sure Want To Cancel')
        if (proceed) {

            fetch(`https://servers.clearsoftwares.net/mnt-rocket/addcancelledbooking`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ ...booking, date: date, time: time })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })

            fetch(`https://servers.clearsoftwares.net/mnt-rocket/getbooking/${booking._id}`, {
                method: 'DELETE',
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    toast.success('Booking Cancelled')

                })
        }

    }

    return (
            <div className="card lg:max-w-lg bg-base-100 shadow-xl text-left">
                <div className="card-body">
                    <h2 className="card-title mx-auto">Name: {booking.person_name}, Id:{booking._id.slice(1, 8)}</h2>
                    <h3 className='text-xl'>Phone: {booking.person_phone}</h3>
                    <h3 className='text-xl'>{booking.service}</h3>
                    <p>Date: {booking.booking_date}</p>
                    <p>Time: {booking.time_slot}</p>
                    <p>Bike Model: {booking.model}</p>
                    <p>Chassis: {booking.chassis}</p>
                    <p>{ }</p>
                    <div className="card-actions">
                        <Link to={`/addsales/${booking._id}`} onClick={() => handleAddBookingCustomer(booking)} className="btn btn-success uppercase text-white mx-auto">Complete Service</Link>
                        {/* <label for="update-modal" onClick={() => setUpdateBooking(booking)} className="btn btn-success uppercase text-white mx-auto">Complete Service</label> */}
                        {user[0]?.email === "riduan@nbcmotors.net" &&
                            <button onClick={() => handleDeleteBooking(booking)} className="btn btn-error uppercase text-white mx-auto">Cancel Service</button>}
                    </div>
                </div>
            </div>
    );
};

export default BookingTable;