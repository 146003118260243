import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editBrand } from '../../../../features/InfoSlice/infoSlice';
import Loader from '../../../Shared/Loader';



const OpeningCapitalField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [openingCapital, setOpeningCapital] = useState([]);
    const [updateOpeningCapital, setUpdateOpeningCapital] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/mnt-rocket/getopeningcapitals')
            .then(res => res.json())
            .then(data => {
                setOpeningCapital(data);
                setLoading(false)
            })
    }, [openingCapital, setOpeningCapital, updateOpeningCapital, setUpdateOpeningCapital])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        fetch('https://servers.clearsoftwares.net/mnt-rocket/addopeningcapital', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Opening Capital Added')
                reset();
            })

    }

    const handleDeleteOpeningCapital = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.clearsoftwares.net/mnt-rocket/getopeningcapital/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateOpeningCapital(null);
                })
        }
    };

    const handleUpdateOpeningCapital = (openingCapital) => {
        setUpdateOpeningCapital(openingCapital);
    }

    const handleApprove=(openingCapital)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.clearsoftwares.net/mnt-rocket/getopeningcapital/${openingCapital._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Opening Capital Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Opening Capital Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Opening Capital Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Opening Balance</span>
                    </label>
                    <input {...register("opening")} type="number" placeholder="Type Opening Balance Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addOpeningCapital} type="submit" value='Add Opening Capital' className='btn bg-red-600 text-white lg:mt-9' />
            </form>

            {
                openingCapital.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Opening Capital Name</th>
                                <th className='bg-red-600 text-white normal-case'>Opening Balance</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                openingCapital.map((openingCapital, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{openingCapital.name}</td>
                                    <td>{openingCapital.opening}</td>
                                    <td> <button className={`btn btn-xs normal-case ${openingCapital.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{openingCapital?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{openingCapital?.user ? openingCapital.user : "User"}</button></td>  
                                    {userAc.approvalOpeningCapital? <td>{openingCapital?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{openingCapital?.approvedBy}</button>: <button onClick={()=>handleApprove(openingCapital)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{openingCapital.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{openingCapital.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editOpeningCapital && <td><button><label for="update-modal" onClick={() => handleUpdateOpeningCapital(openingCapital)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteOpeningCapital && <td><button onClick={() => handleDeleteOpeningCapital(openingCapital._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateOpeningCapital && <UpdateModal setUpdateOpeningCapital={setUpdateOpeningCapital} updateOpeningCapital={updateOpeningCapital}></UpdateModal>
            }
        </div>
    );
};

export default OpeningCapitalField;