import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Shared/Loader';
import { useRef } from 'react';
import AddSalesProductList from './AddSalesProductList';
import AddSalesInvoice from './AddSalesInvoice';
import { selectCustomer } from '../../features/SalesSlice/SalesSlice';
import { format } from 'date-fns';



const AddSales = ({ }) => {
    const customer = useSelector((state) => state.salesInvoice.bookingCustomer);
    console.log(customer.person_name);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [details, setDetails] = useState();
    const today = new Date();

    console.log(details);

    const customerRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingRef = useRef('');
    const dateRef = useRef('');
    const dateRef2 = useRef('');

    const dispatch = useDispatch();



    useEffect(() => {
        // fetch('https://servers.clearsoftwares.net/mnt-rocket/getcustomer')
        //     .then(res => res.json())
        //     .then(data => {
        //         setCustomers(data);
        //         setLoading(false)
        //     })
    }, [])


    // if (loading) {
    //     return <Loader />
    // }

    // const handleSearchResult = (event) => {
    //     setSearchText(event.target.value)
    //     const match = customers.filter(customer => customer.phone.includes(searchText));
    //     setSearchResult(match);
    //     setIsSearching(true);
    // }

    // const handleSelectCustomer = (customer) => {
    //     dispatch(selectCustomer(customer))
    //     setIsSearching(false)
    //     setSearchText('');

    // }


    const handleSalesDetails = (event) => {
        const salesInvoice = event.target.value;
        const salesCustomer = customerRef.current.innerText;
        const salesAddress = addressRef.current.innerText;
        const salesPhone = phoneRef.current.innerText;
        const salesOpening = openingRef.current.innerText;
        const salesDate1 = dateRef.current.value;
        const salesDate2 = dateRef2.current.value;
        const salesDate = salesDate1 ? salesDate1 : salesDate2;

        setDetails({ salesInvoice, salesCustomer, salesAddress, salesPhone, salesOpening, salesDate })

    }


    return (
        <div className='w-full px-12 pb-5 my-5'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A Sales</h1>
            <div className='grid grid-cols-12 gap-4'>
                {/* <div class="form-control w-60 max-w-xs mt-5 col-span-2">
                    <div class="form-control w-60">
                        <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Customer" class="rounded-lg bg-white p-3 text-white text-center placeholder-black focus:border-blue-500 focus:bg-gray-800 focus:outline-none w-full max-w-xs" />
                    </div>
                    <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-16' : "h-8"} w-80 overflow-auto`}>
                        {
                            isSearching ? searchResult.map(customer =>
                                <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>) :
                                customers.map(customer =>
                                    <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>)
                        }
                    </div>
                </div> */}
                <div className='col-span-6 mt-4'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                {/* <th className='bg-red-600 text-white normal-case'>Customer Address</th> */}
                                <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                {/* <th className='bg-red-600 text-white normal-case'>Previews Due</th> */}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td><input ref={customerRef} value={customer?.person_name ? customer?.person_name : customer} type="text" className='rounded-lg bg-red-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full w-60' /></td>
                                <td><input ref={phoneRef} type="text" value={customer?.person_phone} className='rounded-lg bg-red-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                {/* <td ref={customerRef} className='h-8'>{customer?.person_name}</td> */}
                                {/* <td ref={addressRef}>{customer?.address}</td> */}
                                {/* <td className='bg-red-200 mt-2' ref={phoneRef}>{customer?.person_phone}</td> */}
                                {/* <td ref={openingRef}>{customer?.opening}</td> */}
                            </tr>

                        </tbody>
                    </table>
                </div>
                <br />
                <div className='col-span-4 mt-4'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                {customer?.booking_date ? <td><input ref={dateRef} value={customer?.booking_date} type="date" className='rounded-lg bg-red-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td> :
                                    <td><input ref={dateRef2} type="date" className='rounded-lg bg-red-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>}
                                <td><input onChange={handleSalesDetails} type="text" defaultValue={"Inv-0001"} className='rounded-lg bg-red-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div className='grid grid-cols-1 relative'>
                <AddSalesProductList />
                <AddSalesInvoice details={details} />
            </div>
        </div>
    );
};

export default AddSales;