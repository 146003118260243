import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../../features/InfoSlice/infoSlice';


const UpdateModal = ({ updateBankAccount, setUpdateBankAccount }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    // const Bank Account = useSelector((state) => state.info.Bank Account[0]);
    const { _id, name, accountno, opening, status } = updateBankAccount;
    const onSubmit = (data) => {
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        fetch(`https://servers.clearsoftwares.net/mnt-rocket/getbankaccount/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ user, ...data,approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateBankAccount(null);
                toast('Bank Account Upadated Successful');
                
            })
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal">
                <div class="modal-box relative">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update-{name}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Bank Account Name</span>
                            </label>
                            <input {...register("name")} type="text" defaultValue={name} placeholder="Type Bank Account Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Account No</span>
                            </label>
                            <input {...register("accountno")} type="text" value={accountno} placeholder="Type Account No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Opening Balance</span>
                            </label>
                            <input {...register("opening")} type="text" defaultValue={opening} placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Bank Account' className='btn bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;